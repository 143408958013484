
export interface FormStep {
    messages: string[];
    fieldName: string;
    inputType: string;
    rule?: (value: string) => boolean;
    errorMsg: string;
    mask?: string;
    placeholder?: string;
    request?: (message: string) => Promise<string>;
    submitInterceptor?: (submit: () => void, skipUserInput: boolean) => void;
    submitIcon?: string;
    readonly?: boolean;
    errorCallback?: () => void;
    options?: string[];
}

interface Message {
    id: number;
    content: string | string[];
    send: boolean;
    loading: boolean;
}

import { Component, Vue, Prop } from 'vue-property-decorator';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { ResizeObserver  } from 'resize-observer';
// @ts-ignore
import { mask } from 'ke-the-mask';

@Component({ components: { ValidationProvider, ValidationObserver }, directives: { mask }  })
class FormChat extends Vue {

    private mounted(){
        this.loadNextMessages();
        const ro = new ResizeObserver(() => this.onResize());
        const wrapper = this.$refs.wrapper as HTMLElement;
        if(wrapper) {
            ro.observe(wrapper);
        }
    }

    @Prop({ type: Object, required: true })
    private value!: { [key: string]: string };

    @Prop({ type: Array, required: true })
    private steps!: FormStep[];

    @Prop({ type: String, default: '' })
    private userName!: string;

    @Prop({ type: Array, default: () => [] })
    private finishMessages!: string[];

    private messages: Message[] = [];
    private loading: boolean = true;
    private userInput: string = '';

    private get currentStep(): FormStep | null {

        const entry = this.steps.find(el => this.value[el.fieldName].length == 0);
        return entry || null;
    }

    /* ======== M�todos ========= */
    private interceptor(value: string, skipUserInput: boolean = false, isBase64: boolean = false) {
        if(this.currentStep != null) {
            this.userInput = value;
            this.submit(skipUserInput, isBase64);
        }
    }

    private async submit(skipUserInput: boolean = false, isBase64: boolean = false) {
        if(this.userInput.length && this.currentStep != null) {
            this.loading = true;
            const message = this.userInput;

            // Inserir a mensagem do usu�rio
            if(!skipUserInput) {
                let content = message;

                if(this.currentStep!.inputType == 'password') {
                    content = '*********';
                }
                if(isBase64) {
                    content = `<img src="data:image/jpg;base64, ${message}"/>`;
                }

                setTimeout(() => {
                    this.messages.push({
                        id: Math.random(),
                        content: content,
                        send: true,
                        loading: false
                    });
                }, 100 );
            }

            // Resetar o Input do usu�rio
            this.userInput = '';
                    
            if(this.currentStep!.rule && !this.currentStep!.rule(message)) {

                // Inserir mensagem do bot
                setTimeout(() => {
                    this.addBotMessage(this.currentStep!.errorMsg);
                    this.loading = false;
                    this.currentStep?.errorCallback && this.currentStep.errorCallback();
                }, 1000);
            }
            // Mensagem v�lida
            else {
                if(this.currentStep?.request != null) {
                    setTimeout(() => {
                        const loadingMessage = this.addBotMessage('', false);
                        this.currentStep!.request!(message)
                            .then(resp => {
                                loadingMessage.content = resp;
                                this.updateValue(message);
                            })
                            .catch(error => {
                                loadingMessage.content = error;
                            })
                            .finally(() => {
                                loadingMessage.loading = false;
                                this.loading = false;
                            });
                        
                    }, 1000);
                }
                else {
                    this.updateValue(message);
                }
            }
        }
    }

    private updateValue(message: string){
        this.value[this.currentStep!.fieldName] = message;
        this.$nextTick(() => {
            this.loadNextMessages();
            if(this.currentStep == null) {
                this.$emit('fisished');
            }
        });
    }

    private loadNextMessages(){
        const array = this.currentStep?.messages || this.finishMessages;
        array.forEach((message, index, array) => {
            setTimeout(() => {
                this.addBotMessage(message);
                if(index + 1 == array.length) {
                    if(this.currentStep?.options != null) {
                        setTimeout(() => {
                            this.addBotMessage(this.currentStep?.options!);
                        }, 1200);
                    }
                    setTimeout(() => {
                        this.loading = false;
                    }, 1000);
                }
            }, index == 0 ? 1000 : 1200 * (index + 1));
        });
    }

    private addBotMessage(message: string | string[], autoload: boolean = true) {

        const chatMessage: Message = {
            id: Math.random(),
            content: message,
            send: false,
            loading: true
        };
        this.messages.push(chatMessage);
        
        if(autoload) {
            setTimeout(() => {
                chatMessage.loading = false;
            }, 1000);
        }

        return chatMessage;
    }

    private onResize() {
        const el = this.$refs.chatContainer as HTMLElement;
        if(el != null) {
            el.scrollTo(0, el.scrollHeight + 1);
        }
    }
}

export default FormChat;
