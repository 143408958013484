
import { Component, Vue, Prop } from 'vue-property-decorator';
import { ClassImageDTO } from '../DTOs/ClassImageDTO';

@Component({ })
class ImageLoader extends Vue {

    private mounted() {
        this.$store.dispatch('loadImage', this.imageId)
            .then((image: ClassImageDTO) => {
                this.base64 = `data:${image.contentType};base64,${image.base64}`;
                this.$emit('loaded', this.base64);
            })
            .catch(error => {
                console.log(error);
            });
    }

    private loading: boolean = true;
    private base64: string = '';

    private onPressed(){
        if(this.base64.length > 0) {
            this.$emit('onPressed');
        }
    }

    @Prop({ type: String, required: true })
    private imageId!: string;
}

export default ImageLoader;
